
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.aboutText {
  font-size: var(--font-size-xs);
  text-align: center;
  padding-block: 10px;
  margin-top: 40px;

  .heading {
    font-weight: var(--font-weight-medium);
  }

  .text {
    max-width: 860px;
    margin: 0 auto;
  }
}
