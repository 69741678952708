
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.searchButton {
  display: block;
  border-radius: 50%;
  min-width: 42px;
  height: 42px;
  line-height: 54px;
  background-color: rgb(var(--azure));
  text-align: center;

  &:disabled {
    pointer-events: none;
    background-color: rgb(var(--isabelline));
    color: rgb(var(--pastelGrey));
  }

  > svg {
    display: inline-block;
    color: rgb(var(--white));
    font-size: 22px;
  }
}
