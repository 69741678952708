
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.autocompleteBox {
  width: 100%;
  position: relative;
  z-index: var(--floaters-index);

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  &.isOpen {
    @include mobile {
      background-color: rgb(var(--white));
      border-radius: 12px 12px 0 0;
      position: fixed;
      top: 8%;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: var(--popups-index);
      width: 100%;
    }
  }
}

.dropdown {
  background-color: rgb(var(--white));
  overflow: hidden;
  min-height: 0;

  @include desktop {
    margin-top: 10px;
    position: absolute;
    width: 100%;
    border-radius: 12px;
  }

  .groupList {
    overflow-y: auto;
    max-height: 100%;

    @include desktop {
      max-height: 340px;
    }
  }
}
