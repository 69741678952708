
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.textTag {
  padding: 4px 10px;
  line-height: 1.285;
  border-radius: 50px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  vertical-align: middle;

  &.white {
    background-color: rgb(var(--white));
  }

  &.dark {
    background: rgba(0, 0, 0, 0.5);
    color: white;
  }

  &.orange {
    background-color: rgb(var(--safetyOrange));
    color: white;
  }

  > svg {
    height: 1.285em;
    width: 1.285em;
    vertical-align: middle;
    margin-left: 4px;
  }
}
