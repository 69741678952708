
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.groupHeading {
  display: block;
  padding: 10px 20px;
  background-color: rgb(var(--isabelline));
}
