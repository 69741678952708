
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.mobileDrawerHeader {
  padding: 14px;
  position: relative;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);

  .title {
    display: block;
    text-align: center;
  }

  @include desktop {
    display: none;
  }
}
