
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@mixin dots {
  content: '...';
  color: rgb(var(--spanishGrey));
  font-weight: var(--font-weight-regular);
  display: block;
}

.itemContainer {
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  flex-grow: 0;

  &.backDots {
    &::before {
      @include dots();
    }
  }

  &.frontDots {
    &::after {
      @include dots();
    }
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-s);
  color: rgb(var(--spanishGrey));
  position: relative;
  min-width: 36px;
  min-height: 36px;
  padding: 7px 10px;
  line-height: 0;
  border-radius: 27px;

  @mixin selected() {
    &:not([aria-current='false']) {
      @content;
    }
  }

  &.primary {
    @include selected {
      font-weight: var(--font-weight-medium);
      pointer-events: none;
      color: rgb(var(--safetyOrange));
      background: rgb(var(--linen));
    }
  }

  &.yad1 {
    @include selected {
      font-weight: var(--font-weight-medium);
      pointer-events: none;
      color: rgb(var(--azure));
      background: rgb(var(--aliceBlue));
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background: rgb(var(--snow));
    color: rgb(var(--jet));
  }
}
