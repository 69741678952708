
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.autocompleteItem {
  padding: 14px 20px;

  &.highlighted {
    background-color: rgb(var(--snow));
  }
}
