
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.closeButton {
  position: absolute;
  left: 0;
  top: 0;
  padding: 13px;
  font-size: 20px;

  svg {
    display: block;
  }
}
