
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.feedItemBox {
  height: 100%;
  position: relative;

  &:hover .bottomLabel {
    background-color: rgb(var(--aliceBlue));
  }

  .imageBox {
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 3 / 2;
    box-shadow: var(--elevation-small);
  }

  .contentBox {
    margin-top: 14px;
    display: flex;

    .titlesBox {
      flex: 2;
      overflow: hidden;

      .title {
        @include trim;
        line-height: 1.25;
        height: 1.25em;
        font-size: var(--font-size-m);
        font-weight: var(--font-weight-medium);
        margin-bottom: 3px;
      }

      .secondaryTitle {
        @include trim;
        line-height: 1.25;
        height: 1.25em;
        font-size: var(--font-size-s);
        font-weight: var(--font-weight-regular);
      }
    }

    .logoBox {
      flex: 1;
      position: relative;
      height: 32px;

      .logo {
        object-fit: contain;
      }
    }
  }

  .bottomBox .bottomLabel {
    margin-top: 12px;
    display: inline-block;

    @include trim-table;
    max-width: 100%;
    border-radius: 50px;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-regular);
    color: rgb(var(--azure));
    border: 1px solid rgb(var(--azure));
    padding: 4px 12px;
  }

  .likeBox {
    position: absolute;
    overflow: hidden;
    left: 12px;
    top: 12px;

    .likeIcon {
      @include iconXL();
    }
  }
}
