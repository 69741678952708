
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.similarProjectsBox {
  @include mobile {
    margin-inline: -20px;
  }
  .slide {
    width: auto;
  }

  .heading {
    font-size: var(--font-size-l);

    @include desktop {
      margin: 20px 0;
    }

    @include mobile {
      margin: 24px 20px;
    }
  }
}
