
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.coverBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @include desktop {
    height: 360px;
  }
  @include mobile {
    height: 280px;
  }

  .bgBox {
    position: absolute;
    inset: 0;
    z-index: -1;
    overflow: hidden;
    background-image: url('#{$y2AssetsPath}y2_frontend/apps/yad1/cover.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    // Gray filter overlay
    &::after {
      inset: 0;
      content: '';
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .bgVideo {
      object-fit: cover;
      position: relative;
      object-position: center;
      height: 100%;
      width: 100%;

      @include mobile {
        width: 150%;
      }
    }
  }
  .pageHeading {
    text-align: center;
    font-weight: var(--font-weight-medium);
    color: rgb(var(--white));

    @include desktop {
      margin-bottom: 50px;
      font-size: var(--font-size-xxxxxxl);
    }

    @include mobile {
      white-space: pre-wrap;
      margin-bottom: 30px;
      font-size: var(--font-size-xxxxxl);
    }
  }
}
