
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.breadcrumbItemBox {
  font-size: var(--font-size-xxs);
  color: rgb(var(--spanishGrey));
  display: inline;

  &:not(:last-child) {
    &:after {
      display: inline-block;
      margin: 0 6px;
      content: '/';
      font-size: var(--font-size-xxs);
      color: rgb(var(--spanishGrey));
    }
  }
}
