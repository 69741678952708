
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.pagination {
  background-color: rgb(var(--white));
  display: flex;
  align-items: center;
  height: 65px;
  user-select: none;

  @include desktop {
    padding-inline: 10px;
  }

  * {
    transition: 0.2s all;
  }
}

.textVariant {
  margin: 0 auto;

  @include desktop {
    display: none;
  }
}
