
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.button {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  .icon {
    @include iconColor(rgb(var(--jet)));
    font-size: var(--font-size-xl);
  }

  @include desktop {
    &:hover {
      background: rgb(var(--snow));
    }
  }

  &[aria-disabled='true'],
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
