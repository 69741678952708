
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.noSearchResultsBox {
  .noSearchResultsContainer {
    text-align: center;

    @include desktop {
      padding: 40px 0 80px;
    }

    @include mobile {
      padding-bottom: 60px;
    }
  }

  @include mobile {
    padding-top: 54px;
  }
}

.illustration {
  @include desktop {
    width: 158px;
    height: 148px;
  }

  @include mobile {
    width: 142px;
    height: 132px;
  }
}

.heading {
  font-size: var(--font-size-xl);
  display: block;

  @include desktop {
    margin-top: 26px;
  }

  @include mobile {
    margin-top: 22px;
    line-height: 1.5em;
    white-space: pre-wrap;
  }
}
