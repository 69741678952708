
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.ImageBox {
  width: 100%;
  height: 100%;
  position: relative;
  // :TODO: Add the background-image for default

  img {
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .secondaryImage {
    display: none;
  }

  &:hover {
    .secondaryImage {
      display: block;
    }

    // Hide the main image when the secondary image is displayed
    .secondaryImage + .mainImage {
      display: none;
    }

    &:not(:has(.secondaryImage)) .mainImage {
      transform: scale(1.05);
    }
  }

  .tagSlot {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .ghostStyles {
    width: 100%;
    height: 100%;
  }
}
