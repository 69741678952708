
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.list {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  gap: 12px;

  @include mobile {
    display: none;
  }
}
