
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.containerBox {
  width: 100%;
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
}
