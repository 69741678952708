
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.dropdownBox {
  background-color: rgb(var(--white));
  overflow: hidden;
  min-height: 0;
  box-shadow: var(--elevation-large);

  @include desktop {
    margin-top: 10px;
    position: absolute;
    width: 100%;
    border-radius: 12px;
  }

  .groupList {
    overflow-y: auto;
    max-height: 100%;

    @include desktop {
      max-height: 340px;
    }
  }
}
