
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.breadcrumbsBox {
  padding: 20px;

  .breadcrumbsList {
    text-align: center;
  }
}
