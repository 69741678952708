
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.swiperWrapper {
  position: relative;
}

.navigationWrapper {
  position: absolute;
  inset: 0;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;

  .separatorDiv {
    margin: auto;
  }
}

.swiperButtonPrev {
  transform: translateX(16px);
  grid-column: 1;
}

.swiperButtonNext {
  transform: translateX(-16px);
  grid-column: 3;
}

.swiperButtonPrev,
.swiperButtonNext {
  z-index: 2;
  position: relative;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: rgb(var(--white));
  box-shadow: var(--elevation-large);
  cursor: pointer;

  .arrowIcon {
    @include iconLarge();
    vertical-align: middle;
  }

  &::after {
    font-size: var(--font-size-xs);
    color: rgb(var(--black));
    transform: rotate(180deg);
  }

  @include mobile {
    display: none;
  }

  &:disabled {
    display: none;
  }
}
