
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

.autocompleteInputBox {
  width: 100%;
  border-radius: 50px;
  border: 4px solid rgb(var(--white));
  background-color: rgb(var(--white));
  padding-right: 14px;
  font-size: var(--font-size-s);
  color: var(--graniteGrey);
  display: flex;

  input {
    outline: none;
    width: 100%;
    border: none;
    background-color: transparent;

    ::placeholder {
      color: var(--graniteGrey);
    }
  }

  &.isOpen {
    @include mobile {
      padding: 20px;

      input {
        outline: none;
        width: 100%;
        padding: 10.5px 18px;
        line-height: 1;
        border: 1px solid rgb(var(--pastelGrey));
        border-radius: 12px;
        font-size: var(--font-size-s);
      }
    }
  }
}
